export default {
  Message: {
    Logout: '您尚未登陆或已登录超时，请重新登陆',
    Message: '消息',
    Confirm: '确认',
    Cancel: '关闭',
    msg401: '权限不足！',
    msg400: '系统异常！',
    msg404: '请求接口不存在！',
    msg500: '服务器错误！',
    msg502: '网络错误！',
    msg503: '服务不可用，服务器暂时过载或维护。',
    SucceedInOperating: '操作成功',
    SavedSuccessfully: '保存成功',
    Loading: '页面正在加载，请稍候',
    ViewDetail: '了解更多',
    FollowUs: '关注我们',
    Evidence: '凭据',
    UploadEvidence: '上传凭据',
    OrderNumber: '订单编号',
    Remark: '备注',
    Image: '图片',
    SubmitSuccess: '提交成功',
    SubmitFailed: '提交失败',
    DeliveryDate: '送货日期',
    DeliveryTime: '送货时段',
    DeliveryInstructions: '送货指示',
    DeliveryMark: '预订请预早⼀天，每天晚上10 点截单，套餐满800 包市区送货。10点前⼊数⽅可隔天送货。',
    All: '全部',
    PlsAddPiUpAddr: '请添加相关自提地址信息！',
    NoneAddPiUpAddr: '查询不到相关自提地址信息！',
    AdditionalDiscount: '额外折扣已生效',
    Delete: '删除',
    SuccessfullyDeleted: '删除成功',
    Save: '保存',
    SaveAll: '保存所有',
    noRMBStripe: '货币RMB不支持Stripe支付，请选择其他支付方式或切换货币模式',
    textareaError: '请输入5个字符以上',
    FindNoProduct: '系统正忙，请重新刷新页面',
    Readmore: '阅读更多',
    Shopnow: '立即购物',
    InternationalCertification: '国际认证',
    CONTACTUS: '联络我们',
    PaymentMethods: '支付方式',
    ElderlyCareInformation: '长者护理信息',
    Wholesaleinquiry: '批发查询',
    Pointofsale: '搜索销售点',
    SubscribeTips: '订阅安心宝通讯电邮及成为会员，获取独家优惠、最新推出的长者商品、和长者护理的信息。',
    SubscribeBtn: '订阅获取独家优惠',
    checkoutTipsA: '购物满 HK$600.00以上免运费 (注意 : 赤柱 / 东涌 / 离岛 / 西贡或偏远地区运费附加费需要另议)',
    checkoutTipsB: '- 送货上楼梯需加收费用',
    checkoutTipsC: '- 选择自行提取货物的客人必须提前三天致电2417 3236预约 (只收现金)',
    keywords: '请输入关键字',
    Exploremore: '探索更多',
    Discovermore: '发现更多',
    More: '查看更多',
    nomessage: '暂无内容',
    Apply: '报名'
  },
  DeliveryAddress: {
    AddAddress: '新增地址',
    ChooseDeliveryAddress: '选择送货地址',
    EditBtn: '编辑',
    DeleteBtn: '删除',
    AddDeliveryAddress: '添加新的收货地址',
    PostalCode: '邮政编码',
    Area: '地区',
    Province: '省份',
    District: '区份',
    Address: '地址',
    DefaultAddress: '设为默认送货地址?',
    SaveBtn: '保存',
    ResetBtn: '重置',
    CloseBtn: '关闭',
    YesBtn: '是',
    NoBtn: '否',
    AddFirstName: '名字',
    AddLastName: '姓氏',
    UserContactNumber: '联系电话',
    Mobile: '手提电话',
    Addressprovince: '请选择省份',
    AddressArea: '请选择地区',
    Detail: '街道/屋苑/楼层/室'
  },
  Order: {
    OrderNumber: '订单编号',
    OrderDateTime: '订单日期&时间 ',
    TotalPrice: '总价',
    OrderStatus: '订单状态',
    Paid: '已付款',
    Unpaid: '待付款',
    PayNow: '立即支付'
  },
  RegNPay: {
    BackText: '返回',
    UploadButtonText: '上传文件(jpg, png, pdf)',
    UploadingText: '正在上传',
    ConfirmText: '确定',
    UploadSuccessfulText: '上传成功',
    UploadFailText: '上传失败',
    NoFileText: '没有选中文件',
    UploadLengthText: '最多只能上载{0}文件',
    UploadSizeText: '最多只能上传{0}M',
    PleaseSelect: '请选择',
    SubRecord: '提交纪录',
    ReferenceNum: '参考编号',
    SubmissionDate: '提交日期',
    Print: '列印',
    PlsPayWay: '请选择付款方式',
    PreviewTitleText: '请再次确认页面',
    FormatErrorText: '请输入正确电邮地址',
    RequiredText: '请检查你的答案'
  },
  Display: {
    SuccessCase: '成功个案',
    GeneralQuery: '一般查询',
    OfficeHours: '办公时间',
    Hour0: '星期一至五：',
    Hour1: '早上9时至下午12时,',
    Hour2: '下午1时30分至晚上6时',
    Hour3: '星期六、日及公众期假休息',
    AddrInfo: '长沙湾荔枝角道 777号田氏企业中心 3楼 306室 (长沙湾港铁站 B出口)'
  },
  Action: {
    ViewMore: '查看更多',
    SignUp: '立即报名',
    ReturnHomePage: '返回首页',
    OrderDetails: '订单详情',
    ViewDetail: '了解更多'
  },
  Cms: {
    PlsChoose: '请点击选择您想要查看的内容：'
  },
  payment: {
    PaymentSuccessTips: '支付结果： 成功',
    PaymentFailTips: '支付结果： 失败'
  },
  Calendar: {
    KnowMore: '了解更多',
    Time: '时间',
    Venue: '地点',
    NoEvent: '今日暂无活动'
  },
  photo: {
    photo: '相片',
    BackText: '返回上一级'
  },
  pager: {
    Last: '上一页',
    Next: '下一页',
    per: '第',
    page: '页',
    Total: '共'
  },
  Home: {
    Home: '首页',
    Search: '搜索结果',
    ReadMore: '更多'
  },
  Login: {
    doLogin: '登入',
    LoginTitle: '会员登入',
    NewUser: '新用户',
    RegNow: '现在注册',
    ForgetPwd: '忘记密码',
    RememberMe: '记住我',
    FaceBookUse: '使用facebook账户登录',
    UserName: '登录用户名/邮箱地址',
    EnterEmail: '请输入邮箱地址',
    CorrectEmail: '请输入正确邮箱地址',
    LoginPwd: '请输入密码',
    LoginoldPwd: '密码不正确',
    Loginerror: '账号或密码不正确',
    LoginSucceeded: '登入成功！',
    Account: '个人中心',
    MemberLogin: '会员登入'
  },
  Register: {
    RegisterTitle: '注册会员',
    UserEmail: '邮箱',
    or: '或',
    UserRegPassword: '密码',
    UserNewPassword: '新密码',
    UserOldPassword: '旧密码',
    UserRegTips: '最少6个由字母或数字组成的字符',
    UserConfirmPassword: '确认密码',
    UserFirstName: '名字',
    UserLastName: '姓氏',
    UserContactNumber: '联系电话',
    UserBirthDate: '出生日期',
    UserGender: '性别',
    UserMale: '男',
    UserFemale: '女',
    UserLanguage: '语言',
    UserLanguageE: '英文',
    UserLanguageT: '繁体',
    UserLanguageS: '简体',
    RegisterBtn: '注册',
    RegisterAgree: '我同意确认以上所提供的资料正确无误',
    RegisterEmail: '邮箱不能为空',
    RegisterEmail01: '请输入正式的邮箱格式',
    RegisterPassword: '请输入密码',
    RegisterNewPassword: '请再次输入密码',
    RegisterNewPassword01: '两次输入密码不一致',
    RegisterFirstName: '请输入您的姓名',
    RegisterLastName: '请输入您的名称',
    RegisterAgree_check: '请勾选',
    RegisterMobile: '请输入您的手提电话',
    RegisterSuccess: '注册成功',
    RegisterFail: '注册失败',
    RegisterWelcome: '恭喜您,注册成功',
    RegisterTips: '此电子邮箱尚未注册，请立即注册成为会员！',
    Login: '跳转登录',
    Registerdigital: '长度由8至20个字符 须包含大写字母、小写字母及数字',
    ExistingMembers: '对于现有会员，请在 HIEA 网站上注册为在线会员，享受您的会员体验。 请填写以下字段：'
  },
  Notification: {
    MyMessages: '我的信箱',
    MsgList: '消息列表',
    TotalRecQtyWithSymbol: '总记录数 ：',
    UnreadWithSymbol: '未读记录数 ：',
    MsgUnreadOnly: '只看未读消息',
    MsgMarkAsRead: '标记为已读',
    CheckAllOrNot: '全选/反选',
    MsgSender: '发信人',
    MsgSubject: '主题',
    MsgSenderDate: '发信时间',
    MsgReadStatus: '状态',
    Details: '了解详情',
    More: '更多',
    Back: '返回',
    MsgContent: '内容',
    Send: '发送',
    Unread: '未读',
    Read: '已读',
    UpdateSucceeded: '更新成功!',
    Check: '选择',
    Id: '序号'
  },
  Forgetpassword: {
    ForgetPassword: '忘记密码',
    ResetPwdTips: '请输入您用于注册的电子邮件，然后点击“下一步”继续',
    LoginNameEmail: '邮箱',
    NextStep: '下一步',
    ResetPwdSuccess: '重置密码已发送到您的邮箱，请查看。',
    NotEmail: '收不到重设密码电邮',
    ToReEnter: '按此重新填写',
    EnterEmail: '请输入邮箱地址',
    CorrectEmail: '请输入正确的邮箱地址',
    ResetPwd: '重设密码'
  },
  MemberInfo: {
    MemberInfoTitle: '用户信息',
    UserAccount: '用户账号',
    ModifyPassword: '修改密码',
    EnterUserName: '请输入名字',
    EnterUserLastName: '请输入姓氏',
    EnterUserPhone: '请输入联系电话',
    EnterUserBrithDate: '请选择出生日期',
    EnterOldPwd: '请输入密码',
    EnterNewPwd: '请输入新密码',
    EnterComfirmPwd: '请输入确认密码',
    EnterNumber: '请输入数字',
    PwdNotCorrect: '两次密码不一致',
    AgreeEmail: '本人同意天才儿童书店透过电邮地址向我发送推广信息',
    DDMM: '日/月'
  },
  Input: {
    email: '请输入正确的邮箱',
    number: '请输入数字',
    input: '请输入',
    text: '',
    date: '请选择日期',
    password: '两次输入密码不一致',
    textarea: '',
    emailNotFound: '邮箱不存在',
    logopassword: '请输入密码',
    confirmpassword: '两次输入密码不一致',
    nomessage: '暂无数据',
    phoneincorrect: '电话号码格式不正确'
  },
  MessageNew: {
    Search: '搜寻',
    Title: '标题',
    Date: '日期',
    More: '更多',
    President: '会长和专业组别主席的话',
    HIREA: '香港地产行政师学会',
    Event: '活动',
    News: '最新消息',
    Disclaimer: '免责声明',
    CPD: '持续专业发展活动',
    ReadMore: '阅读更多',
    NoContent: '暂无内容',
    Year: '年份',
    Month: '月份',
    All: '所有',
    Hirea: '香港地产行政师学会',
    selectYear: '请选择年份',
    UpdateTime: '更新日期: 2024年11月27日'
  },
  Account: {
    MemberCenter: '会员中心',
    MemberInformation: '用户信息',
    MyMessages: '我的信箱',
    MyFavorite: '我的最爱',
    MyOrder: '我的订单',
    DeliveryAddress: '送货地址',
    Logout: '登出',
    Welcome: '欢迎!',
    SISA: '选择成功',
    Time: '时间'
  },
  Month: {
    January: '一月',
    February: '二月',
    March: '三月',
    April: '四月',
    May: '五月',
    June: '六月',
    July: '七月',
    August: '八月',
    September: '九月',
    October: '十月',
    November: '十一月',
    December: '十二月'
  },
  MemberLogin: {
    MemberLogin: "Member's Login",
    MyPersonalInformation: '我的个人资料',
    MyCPDRecords: '我的持续专业进修活动记录',
    MyApplications: '我的申请及付款纪录',
    MyMailBox: '我的邮箱',
    Update: '更新',
    Back: '返回',
    ModifyPassword: '修改密码'
  },
  MyPersonalInformation: {
    Welcome: '欢迎',
    LastName: '英文姓氏',
    FirstName: '英文名字',
    ChineseName: '中文姓名',
    ClassofMembership: '会员类别',
    MembershipNumber: '会员编号',
    Divisions: '专业组别',
    ContactAddress: '通信地址',
    CurrentCompanyNameandAddress: '现职公司名称及地址',
    OfficeTelNo: '公司电话',
    CompanyEmail: '公司电邮地址',
    Fax: '传真',
    HomeAddress: '住宅地址',
    HomeTelNo: '住宅电话',
    PersonalEmail: '个人电邮地址',
    MobileNoHK: '流动电话(香港) ',
    MainContactNo: '主要联络电话号码',
    receiveInfo: '我想经以下途径收取香港地产行政师学会信息：',
    Post: '邮件',
    Email: '电邮',
    SMSMessage: 'SMS短讯 '
  },
  MyCPDRecords: {
    UpcomingCPDActivities: '即将举行的活动',
    CPDActivitiesRecord: '活动记录',
    ActivityDate: '日期',
    ActivityTitle: '活动名称',
    Category: '类别',
    Subject: '科目',
    ActivityMode: '活动模式',
    CPDPointsAccredited: '所获学分',
    MediumofInstruction: '授课语言',
    Certificate: '登记',
    Register: '注册',
    PSMA: 'PSMA认可CPD',
    EAA: 'EAA认可CPD',
    UploadCertificate: '上传证书',
    Upload: '上传',
    InsertRowAbove: '插入新行',
    TotalCPD: 'CPD总分',
    Edit: '编辑',
    Submit: '提交',
    Type: '类别',
    Lang: '语言',
    UploadFile: '上传文件',
    ModifyFile: '修改文件',
    ViewFile: '查看文件',
    DeleteRows: '删除行',
    SelectDate: '选择日期',
    EmptyData: '不能提交空数据'
  },
  MyApplicationsPaymentRecords: {
    MyApplicationsPaymentRecords: '我的申请及付款纪录',
    Underreview: '审核中（请在三天内提交您的支票）',
    ActivityTitle: '活动项目',
    Referenceno: '交易参考编号',
    Date: '日期',
    Total: '总数(HK$)',
    Membershipexpireson: '会员资格到期',
    Membershiprenewal: '会员续费',
    Status: '状态'
  },
  MembersList: {
    MembersList: '会员名录',
    SearchMember: '搜索会员',
    TipsA: '请在框中输入要搜索的名称',
    TipsB: '请填写以下信息',
    Submit: '提交',
    MembershipNumber: '会员编号',
    NameinEnglish: '英文姓名',
    NameinChinese: '中文姓名',
    Professionaltitle: '专业称号',
    ClassofMembership: '会员类别'
  },
  MembershipApplicationForm: {
    MembershipApplicationForm: '会籍申请表格',
    ClassofMembershipApply: '申请会员类别',
    MembershipNumber: '会员编号',
    StudentMember: '学生会员',
    AssociateMember: '联系会员 (AHIREA)',
    GraduateMember: '毕业生会员',
    ProfessionalMember: '专业会员 (MHIREA)',
    Divisions: '专业组别',
    MaychoosemorethanONEdivision: '请选取期望加入的专业组别',
    PropertyDevelopment: '地产发展及策划',
    LegalPractice: '法律执业及调解',
    ConstructionDesign: '建筑及保育工程',
    CorporateMgtFinance: '企业融资及资产管理',
    PropertyManagement: '物业及设施管理',
    SalesLeasing: '房地产租售及推广',
    RealEstateTechnology: '建设科技及创新',
    Academic: '学术及教育培训',
    Important: '申请人填写及呈交此表格前，须详细阅读及遵照本会入会申请办法及规条处理。',
    FamilyName: '姓 (教授/博士/先生/女士)',
    OtherNames: '名',
    NameinChinese: '中文姓名',
    Nationality: '国籍',
    DateofBirth: '出生日期 (DD / MM / YYYY)',
    HKIDCard: '香港身份证 护照号码',
    CurrentAddress: '现职公司名称及地址',
    OfficeTelNo: '公司电话',
    CompanyEmail: '公司电邮地址',
    HomeAddress: '住宅地址',
    HomeTelNo: '住宅电话',
    PersonalEmail: '个人电邮地址',
    MobileNo: '流动电话 (香港)',
    MainContactNo: '主要联络电话号码',
    Usemailingaddress: '请以 *公司/住宅 地址作为邮寄地址',
    AcademicQualifications: '学术资历',
    Pleaseprovidecopy: '请提供副本',
    Forapplying: '如申请「物业管理组」会员，申请人需要提交符合《物业管理业监管局》，物业管理人(第 1 级)或(第 2 级)牌照之学历要求相关资料。',
    NameofUniversity: '大学/教育机构名称',
    QualificationAchieved: '考获资格 (学位/文凭)',
    Yearattended: '就读年份',
    From: '由 (MM/YY)',
    To: '至 (MM/YY)',
    Remarks: '备注',
    spaceisnotsufficient: '如空间不敷应用，请使用本表格之补充资料页 A，以进一步补充相关资料。',
    StatutoryProfessionalQualifications: '法定专业资历',
    NameofProfessionalInstitution: '专业团体名称',
    LicenceGrade: '牌照、会员资历 (级别/组别)',
    DateObtained: '考获日期 (DD/MM/YY)',
    PropertyManagementServicesAuthority: '物业管理业监管局',
    PMPTier1: '物业管理人 (第 1 级)',
    PMPTier2: '物业管理人 (第 2 级)',
    EstateAgentsAuthority: '地产代理监管局',
    EstateAgent: '地产代理 (个人)',
    EstateSalesperson: '地产营业员',
    ArchitectsRegistrationBoard: '建筑师注册管理局',
    RegisteredArchitect: '注册建筑师',
    EngineersRegistrationBoard: '工程师注册管理局',
    RegisteredProfessionalEngineer: '注册专业工程师',
    SurveyorsRegistrationBoard: '测量师注册管理局',
    RegisteredProfessionalSurveyor: '注册专业测量师',
    PlannersRegistrationBoard: '规划师注册管理局',
    RegisteredProfessionalPlanner: '注册专业规划师',
    LandscapeArchitectsRegistrationBoard: '园境建筑师注册管理局',
    RegisteredLandscapeArchitect: '注册园境建筑师',
    HousingManagerRegistrationBoard: '房屋经理注册管理局',
    RegisteredProfessionalHousingManager: '注册专业房屋经理',
    OtherProfessionalQualifications: '其他专业资历',
    CurrentEmploymentDetails: '现职工作详情',
    personalCV: '请提供详细个人履历',
    CompanyNameCurrent: '公司名称 (现职)',
    CurrentPosition: '现时职位',
    CurrentPositionSince: '现职开始时间 (MM/YY)',
    NatureofDuties: '现时职务范围',
    Pleasetick: '请在适当方格加上剔号',
    SizeofCompany: '公司规模',
    ListedCompany: '上市公司 / 直属员工超过 100 人以上',
    CompanywithA: '公司直属员工有 51-100 人',
    CompanywithB: '公司直属员工有 31-50 人',
    CompanywithC: '公司直属员工有 10-30 人',
    CompanywithD: '公司直属员工少于 10 人',
    FounderExecutiveDirector: '创办人 / 执行董事 / 合伙人 / 主席',
    DirectorGeneralManager: '董事 / 总经理 / 首席执行官 / 总干事',
    SeniorManager: '高级经理 / 专业经理',
    ManagerDeputyManager: '经理 / 副经理 / 助理经理',
    Others: '其他',
    Pleaseshowyourposition: '请以组织架构图表示阁下现时工作的职位及直接管辖员工数目',
    UploadFile: '上传',
    Currenttotalnumber: '现时直接管辖员工总数',
    ScopeofWork: '工作范围',
    ScopeofWorkA: '% 地产行政',
    ScopeofWorkB: '% 其他',
    PastEmploymentHistory: '过往工作经验',
    NameofCompany: '公司名称',
    Position: '职位',
    Period: '期间',
    NatureofDutiesA: '职务范围',
    SizeofCompanyA: '公司规模 (职员总数)',
    SheetB: '如空间不敷应用，请使用本表格之补充资料页 B，以进一步补充相关资料。',
    CurrentandPrevious: '现时及过往公职',
    Particulars: '详情',
    CriminalConvictionRecord: '刑事定罪纪录',
    CriminalConvictionRecordTipsA: '你有否曾因任何刑事罪行在香港或其他地方被定罪?',
    CriminalConvictionRecordTipsB: '你曾否被判处监禁 (包括缓刑) ?',
    CriminalConvictionRecordTipsC: '你曾否面对任何纪律处分 (包括专业团体、地产代理监管局及物业管理业监管局) ?',
    Yes: '有',
    No: '没有',
    I: '本人',
    CriminalConvictionRecordRemark: '就上述提问，如你的答案是「有」，请另加纸张提供有关的资料或解释，包括有关定罪日期、罪名、刑罚及审讯该案的法院名称等。',
    wishtojoinA: '欲申请加入“香港地产行政师学会”成为会员。 本人明白 贵学会将对本人在申请表上所填写之个人资料保密，但会将有关',
    wishtojoinB: '资料提供予贵学会委员会之成员，在会籍审批过程中使用及随时查阅。 本人同意 贵学会对本人资料的查阅．并明白此与《个人资料（私稳）条例》并无抵触。兹声明就本人所言所信，在此表格内所填报之资料均属确实无讹，本人同意遵守所有会规包括经会方在任何时间通过更改之一切会规内容。 本人亦同意此申请表将由 贵学会保存记录直至本人以书面通知 贵学会撤回申请会籍为止。',
    Iencloseherewith: '兹附上支票号码',
    HK$: '港币',
    beingtheassessmentA: '作为审核费用 (港币 250 元) 及首年年费之用．如本人申请不被接纳，会方将',
    beingtheassessmentB: '退回该款项(审核费用除外)予本人。',
    SignatureofApplicant: '申请人签署',
    Date: '日期',
    Proposer: '提名人',
    MustbeaFellow: '必须为资深或专业会员',
    NameofProposer: '提名人姓名',
    ClassofMembership: '会员类别',
    MembershipNo: '会员编号',
    Signature: '签署',
    FOROFFICEUSEONLY: '本会专用',
    ApplicationReceivedon: '接获申请日期',
    Acknowledgmentsent: '通知申请人接获其会籍申请',
    Applicationvetted: '会籍事务小组审查申请',
    ApprovalRejection: '* 执行委员会 接纳 / 反对 为： 专业会员/ 专业会员(物业管理组) /联系会员/ 联系会员(物业管理组) /毕业生会员/学生会员/境外会员',
    ApplicationResult: '会籍申请结果通知予申请人',
    NotesforMembershipApplication: '入会申请须知',
    ClassesofMembership: '会员分类及基本资格',
    TheInstitutehasstrictrequirements: '本会招收会员，务求严谨，以符合本会会纲及政府之要求。若有意成为香港地产行政师学会之会员，申请人原则上需符合有关会员类别之入会要求，如下表所列。唯执行委员会可就个别申请人的资历，豁免其在相应会员类别上的具体要求。',
    BasicRequirements: '基本资格',
    HonoraryFellow: '名誉资深会员',
    FellowMember: '资深会员',
    ProfessionalMemberMHIREA: '专业会员',
    ProfessionalMemberPropertyManagementDivision: '专业会员 (物业及设施管理组)',
    AssociateMemberAHIREA: '联系会员',
    AssociateMemberPropertyManagementDivision: '联系会员 (物业及设施管理组)',
    NonResidentMember: '境外会员',
    SeniorMemberFHIREA: '终身会员',
    BasicRequirementsTipsA: '执行委员推举任可对本会或房地产业有特殊贡献及服务之社会优秀或著名人士',
    BasicRequirementsTipsB1: '执行委员推举',
    BasicRequirementsTipsB2: '持有香港永久居民身份证明',
    BasicRequirementsTipsC1: '任何年龄在 25 岁以上的行政人员，持有本会执行委员会认可或经香港职业及学术资历评审局认证的房地产相关学位或文凭(香港资历架构第 5 级或以上)或已通过本会考评，并拥有至少 5 年地产业经理级相关工作经验；或',
    BasicRequirementsTipsC2: '任何本会已认可的其他专业学会正式成员，并具备成为其他专业学会正式成员后 2 年以上地产业经理级相关工作经验的人士；或',
    BasicRequirementsTipsC3: '非学位持有人，拥有超过 10 年的地产业主管级监督或管理经验。',
    BasicRequirementsTipsC4: '专业会员必须是香港永久性居民身份证持有人。',
    BasicRequirementsTipsC5: '与“专业会员”的基本资格相同，及需符合《物业管理业监管局》物业管理人(第 1 级) 牌照之学历及工作经验要求。',
    BasicRequirementsTipsD1: '任何年龄在 25 岁以上的行政人员，持有合乎本会专业会员相关学历，并拥有至少 3 年的地产业主管级相关工作经验，或',
    BasicRequirementsTipsD2: '任何本会已认可的其他专业学会正式成员，具备地产业主管级相关工作经验的人士，或',
    BasicRequirementsTipsD3: '非学位持有人，拥有超过 6 年的地产业主管级监督或管理经验。',
    BasicRequirementsTipsD4: '与“专业会员”的基本资格相同，及需符合《物业管理业监管局》物业管理人(第1级) 牌照之学历、工作经验及持续专业发展要求。',
    BasicRequirementsTipsE: '完成本会学生会员所就读或获本会认可的相关地产行政课程',
    BasicRequirementsTipsF1: '现就读本会执行委员会认可或批准的地产行政课程',
    BasicRequirementsTipsF2: '本会执行委员会可自行决定将学生会籍延续至有关课程持续时间之后',
    BasicRequirementsTipsG1: '长注海外工作的资深或专业会员或',
    BasicRequirementsTipsG2: '具本会专业会员资历，但未持有香港永久性居民身份证，经本会确认批准其非境内居住人士',
    BasicRequirementsTipsH: '现有会员年满 65 岁及最少持有本会会籍 10 年，经学会批准，可转为终身会员',
    MembershipApplicationProcedures: '入会申请办法及规条',
    Anylocalpractitioners: '本会欢迎所有在香港执业并符合本会专业会员或其他会员类别(包括联系会员)基本资格、声誉及品德良好之地产行政人员加入本会，有意加入本会成为正式会员者，可照下列办法及规条申请会籍：',
    MTA: '详细正确填妥会籍申请表格（可于本会官方网站 http://www. hirea.org.hk 下载），并征得本会一位资深会员或专业会员签署提名，连同下述文件送呈本会（香港邮政总局信箱 4126 号）会籍事务小组主委处理。',
    MTB: '所有呈交之会籍申请表格，须附下列文件方为有效：',
    MTB1: '申请人之身份証或护照影印副本。',
    MTB2: '申请人之二吋乘一吋半彩色正面証件相片乙张。【注：请于相片背后写上申请人之姓名以便识别。为使记录完好，请勿使用「即影即有」相片。】',
    MTB3: '申请人填报之学历、专业资格及从业记录之证明文件影印副本全套。',
    MTB4: '详细从业纪录及现职之名片一张。',
    MTB5: '持续专业发展记录表（若有）',
    MTB6: '支票乙张，抬头写「香港地产行政师学会」，银码为审核费用(港币 250 元)及所申请有关会籍之一年会费。【注：如申请被接纳者，所付支票将作清付申请人之审核费及首年会费费用。如申请暂不被接纳者，将退还所申请有关会籍之一年会费给申请人。各类会籍之年费不同，按年调整，有关确实数目，可在本会网页内查阅。】',
    MTC: '所有「会籍申请」皆先由本会执行委员会下之会籍事务小组详细及严谨审评。如有需要，该组人员将直接联络申请者，要求澄清疑问及提供资料。',
    MTD: '会籍事务小组完成审核有关「会籍申请」后，将提交本会执行委员会覆核及正式表决。所有「会籍申请」之接纳表决，需由执行委员会全体出席委员一致赞成通过方为有效。有关「会籍申请」之结果将随即由会籍事务小组主委直接发覆函给申请人。',
    MTF: '本会保留接纳或拒绝个别「会籍申请」个案而不作任何解释之最终权，所有申请人须尊重及服从本会执行委员会之最后决定，不得异议。',
    MTG: '如有任何疑问或查询，欢迎有意申请者与本会秘书处联络。',
    RemoveRow: '刪除行',
    AddRow: '添加行',
    Submit: '提交',
    Sign: '签署',
    Save: '保存',
    Clear: '清空',
    Preview: '预览',
    Modify: '修改',
    exceed: '超出数量',
    exceedText: '请先删除原有文件，再重新选择文件，因为超出1个文件限制。',
    MaxSize: '文件大小不能超过',
    Secretariat: '秘书处',
    TextMessageOnly: '只限文字讯息',
    Tel: '电话',
    TelContent: '3588 1799    9144 2633',
    Fax: '传真',
    FaxContent: '3007 0675    2280 6582',
    Address: '地址',
    AddressContent: '香港邮政总局信箱 4126 号',
    Email: '电邮',
    EmailCotent: 'info@hirea.org.hk ',
    Website: '网址',
    WebsiteContent: 'http://www.hirea.org.hk '
  },
  BoAForm: {
    MaxSize: '文件大小不能超过',
    Required: '必填',
    Privacy: '私隐政策',
    Terms: '条件与条款',
    Disclaimer: '免责声明',
    Last: '上一页',
    Next: '下一页',
    SelectDate: '选择日期',
    SubmitSuccess: '提交成功',
    SubmitFail: '提交失败',
    CorrectEmail: '请输入正确的电邮',
    CorrectPhone: '请输入正确的电话',
    Code: '代码',
    Identity: '身份',
    ProjectSection: '项目部分',
    ProjectOwner: '项目拥有者',
    exceed: '超出数量',
    exceedText: '请先删除原有文件，再重新选择文件，因为超出1个文件限制。',
    UploadFiles: '上传文件',
    RegSuccessTipsA: '我们正在审核您关于会员账户激活的申请',
    RegSuccessTipsB: '并很快会以电子邮件回覆您',
    ApplySuccess: '您已成功提交阁下的会员资料',
    Savedsuccessfully: '保存成功',
    Expired: '已過期',
    selectProposers: '选择提名人'
  }
};
